import React from 'react';
import cn from 'classnames';
import tagStyles from '@common/assets/styles/main.scss';

interface IColProps {
  colSpan: number;
  children?: React.ReactNode;
  className?: any;
  [rest: string]: any;
}

export const Grid = ({ children }) => {
  return (
    <div className={cn(tagStyles.tagDs, tagStyles.gContainer)}>{children}</div>
  );
};

export const Row = ({
  children,
  marginBottom,
  className,
  ...rest
}: {
  children?: React.ReactNode;
  marginBottom?: number;
  className?: any;
  [rest: string]: any;
}) => {
  return (
    <div
      className={cn(tagStyles.gItem_16, className)}
      style={{ marginBottom }}
      {...rest}
    >
      {children}
    </div>
  );
};

export const Col: React.FC<IColProps> = React.forwardRef<
  HTMLDivElement,
  IColProps
>(({ colSpan, children, className, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(tagStyles[`gItem_${colSpan || '1'}`], className)}
      {...rest}
    >
      {children}
    </div>
  );
});

export default Grid;
