import { useMsal } from '@azure/msal-react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Logo from '@resources/logo.svg';
import NotificationProvider from '@common/NotificationProvider';
import MessageBar from '@common/MessageBar';
import ReleaseWarning from '@common/ReleaseWarning';
import {
  useGetAppConfigQuery,
  useGetTokenQuery,
} from '@redux/api/portalApiSlice';
import tagStyles from '@common/assets/styles/main.scss';
import { useGetPermissionsByUserIdQuery } from '@redux/api/securityApiSlice';
import { useAppInsights } from '@common/utils/AppInsightsProvider';
import TrackingEvents from '@common/utils/TrackingEvents';
import { useGetMyProfilePhotoQuery } from '@redux/api/graphApiSlice';
import Popup from '@common/Popup';
import Card from '@common/Card';
import CheckIcon from '@mui/icons-material/Check';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useUILayout } from '@common/utils/UILayoutProvider';
import i18n from '@i18n/i18n';

import styles from './styles.module.scss';

const Header = () => {
  const { t } = useTranslation('common');
  const { trackEvent } = useAppInsights();
  const { data: photo } = useGetMyProfilePhotoQuery();
  const { data: tokenData } = useGetTokenQuery();
  const { data: appConfig } = useGetAppConfigQuery();
  const { data: permissions } = useGetPermissionsByUserIdQuery(tokenData?.oid, {
    skip: !tokenData?.oid,
  });

  const { instance } = useMsal();
  const { isSidebarOpen } = useUILayout();

  const onLogout = () => {
    trackEvent(TrackingEvents.LOGOUT);
    instance.logoutRedirect();
  };

  return (
    <div className={cn(styles.header, { [styles.sideBarOpen]: isSidebarOpen })}>
      <div className={tagStyles.upperMenu}>
        <div className={cn(tagStyles.upperMenuLeft, styles.appTitle)}>
          <img src={Logo} />
          <h4>Career Assistant</h4>
          {appConfig?.environmentConfig?.environmentName !== 'PRD' && (
            <div className={styles.envLabel}>
              {appConfig?.environmentConfig?.environmentName}
            </div>
          )}
        </div>
        <div className={tagStyles.upperMenuRight}>
          <NotificationProvider />
          <div className={tagStyles.avatarWrapper} data-hj-suppress>
            <div className={cn(tagStyles.avatarSmall, styles.avatar)}>
              {photo ? (
                <img className={styles.avatarImg} src={photo} />
              ) : (
                `${
                  tokenData?.firstName ? tokenData?.firstName?.charAt(0) : ''
                }${tokenData?.lastName ? tokenData?.lastName?.charAt(0) : ''}`
              )}
            </div>
            <div className={tagStyles.avatarTextWrapper} data-hj-suppress>
              <span className={tagStyles.avatarTitle}>{`${
                tokenData?.firstName || ''
              } ${tokenData?.lastName || ''}`}</span>
              <span className={tagStyles.avatarSubtitle}>
                {permissions?.[0]?.roleName}
              </span>
            </div>
          </div>
          <Popup
            trigger={
              <button className={tagStyles.upperMenuInfo}>
                <MoreVertIcon />
              </button>
            }
          >
            {(dismissPopup) => (
              <Card className={styles.dropdownMenu}>
                <div className={styles.userActionItem}>
                  {tokenData?.userDisplayName}
                </div>
                <div
                  className={styles.userActionItem}
                  onClick={() => {
                    window.open(
                      'https://adecco.service-now.com/sp?id=sc_category&sys_id=f839dfbadb1d701052b9edc405961914&catalog_id=-1',
                      '_blank',
                      'noopener'
                    );
                    dismissPopup();
                  }}
                >
                  <SupportAgentIcon /> Raise a new request
                </div>
                <div
                  className={styles.userActionItem}
                  onClick={() => {
                    window.open(
                      'https://adecco.service-now.com/sp?id=sc_cat_item&sys_id=e6971fbe1bd9b0100b73b841dd4bcb41',
                      '_blank',
                      'noopener'
                    );
                    dismissPopup();
                  }}
                >
                  <ReportProblemIcon /> Report an incident
                </div>
                <div
                  className={styles.userActionItem}
                  onClick={() => {
                    onLogout();
                    dismissPopup();
                  }}
                >
                  <LogoutIcon /> {t('layout:logout')}
                </div>
              </Card>
            )}
          </Popup>
          <Popup
            trigger={
              <div className={styles.languageSwitcher}>
                {i18n.language?.substring(0, 2)?.toUpperCase()}
                <ExpandMoreIcon />
              </div>
            }
          >
            {(dismissPopup) => (
              <Card className={styles.dropdownMenu}>
                <div
                  className={cn(styles.userActionItem, {
                    [styles.selected]:
                      i18n.language?.substring(0, 2)?.toUpperCase() === 'EN',
                  })}
                  onClick={() => {
                    dismissPopup();
                    i18n.changeLanguage('en');
                  }}
                >
                  <div>English</div>
                  {i18n.language?.substring(0, 2)?.toUpperCase() === 'EN' && (
                    <CheckIcon />
                  )}
                </div>
                <div
                  className={cn(styles.userActionItem, {
                    [styles.selected]:
                      i18n.language?.substring(0, 2)?.toUpperCase() === 'ES',
                  })}
                  onClick={() => {
                    dismissPopup();
                    i18n.changeLanguage('es');
                  }}
                >
                  <div>Spanish</div>
                  {i18n.language?.substring(0, 2)?.toUpperCase() === 'ES' && (
                    <CheckIcon />
                  )}
                </div>
                <div
                  className={cn(styles.userActionItem, {
                    [styles.selected]:
                      i18n.language?.substring(0, 2)?.toUpperCase() === 'FR',
                  })}
                  onClick={() => {
                    dismissPopup();
                    i18n.changeLanguage('fr');
                  }}
                >
                  <div>French</div>
                  {i18n.language?.substring(0, 2)?.toUpperCase() === 'FR' && (
                    <CheckIcon />
                  )}
                </div>
              </Card>
            )}
          </Popup>
        </div>
      </div>
      <ReleaseWarning />
      <MessageBar />
    </div>
  );
};

export default Header;
