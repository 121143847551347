import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TemplateQuestion } from '@appTypes/Conversation.types';

interface IQuestionListSliceValues {
  selectedQuestions: TemplateQuestion[];
  myCreatedQuestions: TemplateQuestion[];
  incompatibleQuestions: any[];
  mandatoryQuestionsCount: number;
}

const initialState: IQuestionListSliceValues = {
  selectedQuestions: [],
  incompatibleQuestions: [],
  myCreatedQuestions: [],
  mandatoryQuestionsCount: 0,
};

const questionListSlice = createSlice({
  name: 'questionList',
  initialState,
  reducers: {
    addSelectedQuestion: (
      state,
      action: PayloadAction<{ question: TemplateQuestion; language: string }>
    ) => {
      const { question, language } = action?.payload;

      if (question.forbiddenQuestions) {
        state.incompatibleQuestions = [
          ...state.incompatibleQuestions,
          ...(question?.forbiddenQuestions[language]?.map((item: string) => ({
            [item]: question?.Tag,
          })) || []),
        ];
      }

      state?.incompatibleQuestions?.forEach((item) => {
        const selectedIncompatibleQuestion = state.selectedQuestions.find(
          (question) => question.Tag === Object.keys(item)[0]
        );
        if (selectedIncompatibleQuestion) {
          state.selectedQuestions = state.selectedQuestions.filter(
            (question) => question.id !== selectedIncompatibleQuestion.id
          );
        }
      });

      const mandatoryQuestionsCount = state?.selectedQuestions?.filter(
        (question) =>
          question.mandatoryInCountries?.find(
            (item) => item.countryCode === language
          )
      )?.length;

      state.mandatoryQuestionsCount = mandatoryQuestionsCount;

      if (!state.selectedQuestions.find((item) => item.id === question.id)) {
        if (mandatoryQuestionsCount > 0) {
          const index = state.selectedQuestions.findIndex((question) =>
            question.mandatoryInCountries?.find(
              (item) =>
                item.countryCode === language &&
                item.questionPosition === 'last'
            )
          );
          state.selectedQuestions = [
            ...state.selectedQuestions.slice(0, index),
            question,
            ...state.selectedQuestions.slice(index),
          ];
        } else {
          state.selectedQuestions.push(question);
        }
      }
    },
    removeSelectedQuestion: (
      state,
      action: PayloadAction<{ question: TemplateQuestion; language: string }>
    ) => {
      const { question, language } = action?.payload;

      if (question?.forbiddenQuestions?.[language]) {
        state.incompatibleQuestions = state.incompatibleQuestions.filter(
          (item) => item[Object.keys(item)[0]] !== question.Tag
        );
      }

      state.selectedQuestions = state.selectedQuestions?.filter(
        (item) => item.id !== question.id
      );
    },
    addAllOfCategory: (
      state,
      action: PayloadAction<{ category: any; language?: string }>
    ) => {
      const { category, language } = action?.payload;

      category?.questions?.forEach((question: TemplateQuestion) => {
        if (
          !state.selectedQuestions.find(
            (item: TemplateQuestion) => item.id === question.id
          )
        ) {
          state.selectedQuestions.push(question);
        }
      });

      category?.questions?.forEach((question: TemplateQuestion) => {
        if (question.forbiddenQuestions?.[language]?.length > 0) {
          question.forbiddenQuestions?.[language]?.forEach((id: string) => {
            state.selectedQuestions = state.selectedQuestions.filter(
              (question: TemplateQuestion) => question.Tag !== id
            );
            state.incompatibleQuestions = [
              ...state.incompatibleQuestions,
              { [id]: question.Tag },
            ];
          });
        }
      });
    },
    removeAllOfCategory: (
      state,
      action: PayloadAction<{ category: any; language?: string }>
    ) => {
      const { category, language } = action?.payload;

      state.selectedQuestions = state.selectedQuestions.filter(
        (question: TemplateQuestion) => question.categoryId !== category.id
      );

      category?.questions?.forEach((question: TemplateQuestion) => {
        if (question.forbiddenQuestions?.[language]?.length > 0) {
          question.forbiddenQuestions?.[language]?.forEach((id: string) => {
            state.incompatibleQuestions = state.incompatibleQuestions?.filter(
              (item: string) => Object.keys(item)[0] !== id
            );
          });
        }
      });
    },
    removeAllSelectedQuestions: (state, action) => {
      state.selectedQuestions = state.selectedQuestions?.filter((question) =>
        question.mandatoryInCountries?.find(
          (country) => country.countryCode === action.payload
        )
      );
    },
    addMyCreatedQuestions: (
      state,
      action: PayloadAction<TemplateQuestion[]>
    ) => {
      const questions = action?.payload;
      state.myCreatedQuestions = questions;
    },
    clearQuestionListState: (state) => {
      state.incompatibleQuestions = initialState.incompatibleQuestions;
      state.mandatoryQuestionsCount = initialState.mandatoryQuestionsCount;
      state.myCreatedQuestions = initialState.myCreatedQuestions;
      state.selectedQuestions = initialState.selectedQuestions;
    },
  },
});

export const {
  addAllOfCategory,
  addSelectedQuestion,
  removeAllOfCategory,
  removeSelectedQuestion,
  removeAllSelectedQuestions,
  addMyCreatedQuestions,
  clearQuestionListState,
} = questionListSlice.actions;

export default questionListSlice.reducer;
