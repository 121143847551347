import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const contactListSlice = createSlice({
  name: 'contactList',
  initialState: {
    formState: null,
  },
  reducers: {
    setFormState: (state, action: PayloadAction<any>) => {
      state.formState = action.payload;
    },
    clearOutreachMethodState: (state) => {
      state.formState = null;
    },
  },
});

export const { setFormState, clearOutreachMethodState } =
  contactListSlice.actions;
export default contactListSlice.reducer;
